.mastcontainer-no-mastnav {
  padding-left: 0 !important;
  padding-top: var(--topbar-height)
}
.cardscontainer {
  margin: 2rem;
}
.card {
  cursor: pointer;
  transition: .2s ease-in-out;
}
.card:hover {
  opacity: .5;
}

.disabled-link {
  pointer-events: none;
}

.grey
{
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        -moz-filter: grayscale(1);
        -o-filter: grayscale(1);
        -ms-filter: grayscale(1);
}